
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { GobiColor } from '@/helpers/color';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import settingx from '@/store/modules/settingx';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';

@Component({
	components: {
		StackedBarChartFilterable,
		IncludeTaxToggle,
		SelectFilter,
	},
})
export default class StockEstimatedProfitBarChart extends FilterWidget {
	// @Prop({ default: () => [] })
	// public readonly selectedStockGroupValue!: string[];

	public get permissionIds(): PermissionsGroup[] {
		return ['stocks', 'sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'defaultStockGroup'
	> = ['defaultStockGroup', 'agents', 'dateRange'];

	public legends = ['Total Sales', 'Cost', 'Profit'];

	public xAxisData: string[] = [];
	public selectedStockGroup: string = this.allStockGroup[0];
	public series: echarts.ECharts[] | any = [];

	public mounted() {
		this.selectedStockGroup = this.allStockGroup[0];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get allStockGroup() {
		return Object.values(gdbx.stockGroupNames);
	}

	public get expensiveHook() {
		const {
			includeTax,
			selectedDateRange,
			selectedAgents,
			selectedStockGroup,
		} = this;
		return JSON.stringify([
			includeTax,
			selectedDateRange,
			selectedAgents,
			selectedStockGroup,
		]);
	}

	public async expensiveCalc() {
		const selected = gdbx.allStockGroups.find(
			(vt) => vt.text === this.selectedStockGroup,
		);

		const ref = FilteredDatabase.ref('sales')
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange)
			.stockItems(selected ? selected.value : this.selectedStockItems);

		let pa = 0.1;
		let pb = 0;

		const itemList = [...(await (await ref.get()).getSet('itemCode'))];

		const agentList = [...(await (await ref.get()).getSet('agent'))];

		pb += pa;
		pa = 0.1;

		const ref2 = await FilteredDatabase.ref('sales')
			.agents(agentList)
			.dateRange(this.selectedDateRange)
			.stockItems(itemList);

		pb += pa;
		pa = 0.2;

		const stockItemDatas: DimensionData[] = await this._loadDimensionByFilters(
			ref2,
			'stockItems',
			pa,
			pb,
		);

		pb += pa;
		pa = 0.2;

		const totalDatas: DimensionData[] = await this._loadDimension(
			ref2,
			stockItemDatas,
			pa,
			pb,
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		pb += pa;
		pa = 0.2;

		const costDatas: DimensionData[] = await this._loadDimension(
			ref2,
			stockItemDatas,
			pa,
			pb,
			'cost',
		);

		pb += pa;
		pa = 0.2;

		let profitDatas: DimensionData[] = await this._loadDimension(
			ref2,
			stockItemDatas,
			pa,
			pb,
			this.includeTax ? 'profitIncludeTax' : 'profit',
		);

		profitDatas = FilterWidget.limitSlices(profitDatas).filter(
			(dd) => dd.sum !== 0,
		);

		this.xAxisData = profitDatas.map((dd) => dd.value[0] as string);

		const result = this.xAxisData.map((stock) => {
			const profitIndex = profitDatas.map((dd) => dd.value[0]).indexOf(stock);
			const costIndex = costDatas.map((dd) => dd.value[0]).indexOf(stock);
			const salesIndex = totalDatas.map((dd) => dd.value[0]).indexOf(stock);

			const object = {
				name: '',
				sales: 0,
				cost: 0,
				profit: 0,
				profitMargin: 0,
			};

			object.name = stock;
			object.sales = totalDatas[salesIndex].sum;
			object.cost = costDatas[costIndex].sum;
			object.profit = profitDatas[profitIndex].sum;
			object.profitMargin =
				totalDatas[salesIndex].sum !== 0
					? (profitDatas[profitIndex].sum / totalDatas[salesIndex].sum) * 100
					: 0;

			return object;
		});

		const profit = {
			name: 'Profit',
			type: 'bar',
			barWidth: '40%',
			stack: 'a',
			color: GobiColor.COLOR1,
			data: result.map((item) => item.profit),
		};

		const cost = {
			name: 'Cost',
			type: 'bar',
			barWidth: '40%',
			stack: 'a',
			color: GobiColor.COLOR3,
			data: result.map((item) => item.cost),
		};

		const total = {
			name: 'Total Sales',
			type: 'bar',
			barWidth: '10%',
			color: GobiColor.COLOR5,
			data: result.map((item) => item.sales),
		};

		const profitMargin: echarts.ECharts | any = {
			name: 'Profit Margin (%)',
			type: 'bar',
			barWidth: '5%',
			itemStyle: {
				opacity: 0,
				color: false,
			},
			tooltip: {
				backgroundColor: 'rgba(0, 0, 0, 0)',
			},
			color: GobiColor.COLOR7,
			data: result.map((item) => item.profitMargin),
		};

		this.series = [total, cost, profit, profitMargin];

		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
