
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import gdbx from '@/store/modules/gdbx';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import StockGroupSalesTreeMap from '@/components/reports-v2/components/codedWidgets/stock/StockGroupSalesTreeMap.vue';
import StockGroupPurchaseTreeMap from '@/components/reports-v2/components/codedWidgets/stock/StockGroupPurchaseTreeMap.vue';
import StockEstimatedProfitBarChart from '@/components/reports-v2/components/codedWidgets/stock/StockEstimatedProfitBarChart.vue';
import ClosingStockByLocation from '@/components/reports-v2/components/codedWidgets/stock/ClosingStockByLocation.vue';

@Component({
	components: {
        ClosingStockByLocation,
		StickyFilterContainer,
		DateRangeFilter,
		LastSync,
		SelectFilter,
		StockGroupSalesTreeMap,
		StockGroupPurchaseTreeMap,
		StockEstimatedProfitBarChart,
	},
})
export default class StockOverview extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('month').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('month').valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public get selectedCustomers() {
		return gdbx.allCustomers.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
