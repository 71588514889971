
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	DimensionData,
	PermissionsGroup,
	PurchaseDetailCard,
} from '@/store/models.def';
import * as echarts from 'echarts';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import SwitchAmountQuantityToggle from '@/components/SwitchAmountQuantityToggle.vue';
import settingx from '@/store/modules/settingx';
import { TooltipFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import { nestedGroupBy } from '@/util/nestedGroupBy';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import Button from 'primevue/button';

@Component({
	components: {
		TreeMapFilterable,
		IncludeTaxToggle,
		SwitchAmountQuantityToggle,
    SelectFilter,
    Button,
	},
})
export default class StockGroupPurchaseTreeMap extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['stocks', 'purchases'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['stockItems', 'agents', 'dateRange'];

	public result: any = [];
	public switchAmountQuantity: string = 'amount';
	public series: echarts.ECharts[] | any = [];
	public treeMapName: string = 'Stock Group';
  public isConfirmApply: number = 0;

  public selectedFilter: string = this.filterOptions[0];

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get tooltipFormatter() {
		return this.switchAmountQuantity === 'amount'
			? TooltipFormatter.basicTreeMap
			: TooltipFormatter.treeMapWithoutCurrency;
	}

	public get dataViewFormatter() {
		return this.switchAmountQuantity === 'amount'
			? DataViewFormatter.treemap
			: DataViewFormatter.treemapWithoutCurrency;
	}

	public triggerSwitchAmountQuantity(value) {
		this.switchAmountQuantity = value;
	}

  public get filterOptions() {
    const filterOptionsListAmount = [
      'Top 10 for each category',
      'Bottom 10 for each category',
      'All',
    ];

    const filterOptionsListQuantity = [
      'Top 10 for each category',
      'Bottom 10 for each category',
      'All',
      'F.O.C.',
    ];
    return this.switchAmountQuantity === 'amount' ? filterOptionsListAmount : filterOptionsListQuantity;
  }

	public get expensiveHook() {
		const {
      isConfirmApply,
			includeTax,
			switchAmountQuantity,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		} = this;
		return JSON.stringify([
      isConfirmApply,
			includeTax,
			switchAmountQuantity,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		// const ref = FilteredDatabase.ref('purchases')
		// 	.agents(this.selectedAgents)
		// 	.stockItems(this.selectedStockItems)
		// 	.dateRange(this.selectedDateRange);
    //
		// const purchaseCards: PurchaseDetailCard[] = await (
		// 	await ref.get()
		// ).getCards();
    //
		// const groupedCards = nestedGroupBy(purchaseCards, [
		// 	'stockGroup',
		// 	'itemCode',
		// ]);

    const stockPurchase = await FilteredDatabase.ref('stockPurchase')
        .dateRange(this.selectedDateRange)
        .get();
    const stockPurchaseCards = await stockPurchase.getCards();

    const stockRef = await FilteredDatabase.ref('globalStocks').get();
    const stockCards = await stockRef.getCards();
    const groupedStockCards = nestedGroupBy(stockCards, ['itemCode']);

    const groupedCards = nestedGroupBy(stockPurchaseCards,
        ['stockGroup', 'itemCode']);
		  this.result = [];

		  for (const stockGroup of Object.keys(groupedCards)) {
        const groupDesc = stockCards.findIndex((a) => a.stockGroup === stockGroup);
			     if (groupedCards.hasOwnProperty(stockGroup)) {
				const object = {
					name: stockCards[groupDesc] ? `[${stockGroup}]` + stockCards[groupDesc].stockGroupDesc : stockGroup,
					value: 0,
					color: [
						'#8975ea',
						'#acbeff',
						'#accdff',
						'#acdaff',
						'#cdc8f9',
						'#c7b5e2',
					],
					colorSaturation: [0.9, 0.4],
					children: [] as Array<{ name; value }>,
				};

				const itemObjects = groupedCards[stockGroup];

				for (const item in itemObjects) {
					if (itemObjects.hasOwnProperty(item)) {
						let itemCards = itemObjects[item];

      if (this.switchAmountQuantity === 'quantity' && this.selectedFilter === 'F.O.C.') {
              itemCards = itemObjects[item].filter((a) => a.quantity === 0);
            }

						const itemValueSum = itemCards.reduce(
							(a, b) =>
								a +
								(this.switchAmountQuantity === 'amount'
									? this.includeTax
										? b.cost + b.tax
										: b.cost
									: Math.abs(b.quantity)),
							0,
						);

						object.children.push({
              name: '[' + item + '] ' + groupedStockCards[item][0].itemDesc,
							value: itemValueSum,
						});

						object.value += itemValueSum;
					}
				}

    if (this.selectedFilter === 'Top 10 for each category') {
               if (object.children.length > 10) {
                 object.children.splice(10, object.children.length - 10);
                 this.result.push(object);
               } else {
                 this.result.push(object);
               }
             } else if (this.selectedFilter === 'Bottom 10 for each category') {
               if (object.children.length > 10) {
                 object.children.reverse().splice(10, object.children.length - 10);
                 this.result.push(object);
               } else {
                 object.children.reverse();
                 this.result.push(object);
               }
             } else {
               this.result.push(object);
             }

			}
		}
		  this.treeMapName =
			groupedCards.length === 1 ? groupedCards[0].text : 'Stock Group';

		  this.series = [];

		  const stock = {
			name: this.treeMapName,
			type: 'treemap',
			roam: 'move',
			width: '100%',
			// drillDownIcon: '👆🏻',
			drillDownIcon: '',
			// leafDepth: 1,
			label: {
				color: '#000',
			},
			itemStyle: {
				gapWidth: 1,
				borderWidth: 1,
			},
			levels: [
				{
					itemStyle: {
						// borderColor: '#555',
						borderWidth: 2,
						gapWidth: 2,
					},
					upperLabel: {
						show: false,
						fontWeight: 'bold',
						color: '#000',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 2,
						},
						upperLabel: {
							show: true,
							fontWeight: 'bold',
							color: '#000',
						},
					},
				},
				{
					colorSaturation: [0.3, 0.6],
					itemStyle: {
						borderColorSaturation: 0.7,
						gapWidth: 2,
						borderWidth: 2,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
				{
					itemStyle: {
						// borderColor: '#555',
						gapWidth: 1,
						borderWidth: 1,
					},
					upperLabel: {
						show: true,
						color: '#000',
						fontWeight: 'bold',
					},
					emphasis: {
						itemStyle: {
							borderWidth: 0,
							gapWidth: 1,
						},
						upperLabel: {
							show: true,
							color: '#000',
							fontWeight: 'bold',
						},
					},
				},
			],
			data: this.result,
		};

		  this.series = [stock];

		  this.saveHistory('result', 'series', 'switchAmountQuantity');
	}

  public onHovering(value: boolean, filterId: string) {
    this.$emit('hovering', value, filterId);
  }
  public onEditing(value: boolean, filterId: string) {
    this.$emit('editing', value, filterId);
  }
  public applyFilter() {
    this.isConfirmApply += 1;
  }
}
